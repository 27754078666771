<template>
<div class="dashboard__container--body">
  <div class="dashboard__container--body--col shiftCard">
  	<div class="whiteBack">
      <!-- <h4 v-if="showtitle"class="mb-3">Select Your Jobs</h4> -->
      
      <div class="text-left" v-if="skillCategories && skillCategories.length >= 1">
        <h3 class="mb-5">Please select the positions you are qualified for and interested in working:</h3>

        <div v-if="submitButton" class="mb-3">Please select at least 3 skills.</div>
        <div class="mb-5 flex justify-space-between" v-if="!showCats && activeCat">
          <h4>{{activeCat.title}}</h4>
          <button class="btn btn__outlined" @click="goBackToCats()">Go Back<i class="fas fa-arrow-left ml-3"></i></button>
        </div>
        <div class="mb-5" v-if="showCats && !activeCat">
          <label for="pickCats">Select Job Category:</label>
          <div class="flex flex-column mt-3 mb-3">
            <button class="hoverable btn btn__outlined btn__xlarge flex justify-space-between pt-4 pb-4 mb-3" style="width:100%; padding-right:2.4rem;" v-for="(cat, index) in skillCategories" :key="index" @click="selectCat(cat)">
              <span>{{cat.title}}</span>
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          </div>
          <!-- <v-select
            class="mt-2"
            label="title" 
            :options="skillCategories"
            v-model="activeCat"
            @input="changeCat()"
            >
          </v-select> -->
        </div>
        <transition name="fadeStop">
          <div v-if="activeCat" class="mb-3">
            
          <div class="flex mb-3 mt-3 align-start" v-for="(job, index) in jobsByCategory" :key="job.id">
            <div class="inline" style="width:2.4rem;" >
              <input class="mb-0 mt-0" type="checkbox" :id="index" :value="job" v-model="userProfile.skills" @change="updateSkills()" />
            </div>
            <div class="flex flex-column" style="width:calc(100% - 2.4rem);">
              <span class="caption strong" :for="index">{{job.title}}</span>
              <span class="caption">{{job.description}}</span>
              <span class="caption" v-if="job.url">
                <a :href="job.url" target="_blank">job.url
                </a>
              </span>
            </div>
          </div>

          <!-- <button class="btn btn__primary btn__large mt-3" :disabled="!userProfile.skills || userProfile.skills.length == 0" @click="updateSkills()">
            Update
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button> -->

         </div>
        </transition>
      </div>
    </div>
  </div>
  <div class="dashboard__container--body--col">
    <div class="whiteBack">
      <div class="mb-3" v-if="(userSkills && userSkills.length >= 1)">
        <h4>Current Skills:</h4>
        <div class="flex mb-3 mt-3 align-start" v-for="(job, index) in userSkills" :key="job.id">
          <div class="inline pr-3" style="width:auto;" >
            <button class="btn btn__xsmall btn__error" @click="deleteSkill(job)">Delete</button>
            <!-- <input class="mb-0 mt-0" type="checkbox" :id="index" :value="job" v-model="userProfile.skills" @change="deleteSkill(job)" /> -->
          </div>
          <div class="flex flex-column" style="width:calc(100% - 2.4rem);">
            <span class="caption strong" :for="index">{{job.title}}</span>
            <span class="caption">{{job.description}}</span>
            <span class="caption" v-if="job.url">
              <a :href="job.url" target="_blank">job.url
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
  .hoverable:hover {
    padding-right:1.6rem !important;
  }
</style>

<script>
import { mapState } from 'vuex'

export default {
  props: ['userProfile', 'showtitle', 'submitButton'],
  data: () => ({
    activeCat: null,
    showCats: true,
    skills: [],
    performingRequest: false,
    // jobs: [
    //   { title: 'Janitor'},
    //   { title: 'Day Porter'},
    //   { title: 'Housekeeper'},
    //   { title: 'Concessions Staff'},
    //   { title: 'Zone Supervisor'},
    //   { title: 'Bar Manager'},
    //   { title: 'Bartender'},
    //   { title: 'Beer and Wine'},
    //   { title: 'Barback'},
    //   { title: 'Servers'},
    //   { title: 'Bussers'},
    //   { title: 'Dishwasher'},
    //   { title: 'Food Runner'},
    //   { title: 'Line Cook'},
    //   { title: 'Fry Cook'},
    //   { title: 'Prep Cook'},
    //   { title: 'Banquet Cook'},
    //   { title: 'Banquet Server'},
    //   { title: 'Barista'},
    //   { title: 'Coat Check Attendant'},
    //   { title: 'Parking Lot Attendant'},
    //   { title: 'Guest Services Rep'},
    //   { title: 'Conversion Crew'},
    //   { title: 'Usher'},
    //   { title: 'Ticket Taker'},
    //   { title: 'Grounds Crew'},
    //   { title: 'Gardener/Landscaper'},
    //   { title: 'Warehouse Worker'},
    //   { title: 'General Labor'},
    //   { title: 'Security Guard'},
    //   { title: 'ID Checker'},
    //   { title: 'Promo Model'},
    // ]
  }),
  computed: {
    ...mapState(['jobs', 'skillCategories', 'jobsByCategory']),
    userSkills() {
      if (this.userProfile.skills && this.userProfile.skills.length >= 1) {
        return this.userProfile.skills.filter(skill => {
          return skill.id
        })
      } else {
        return []
      }
    }
  },
  created () {
    this.$store.dispatch("getSkillCategories")
    // if (!this.skillCategories || this.skillCategories.length < 1) {
    //   this.$store.dispatch("getSkillCategories")
    // }
  },
  methods: {
    goBackToCats() {
      this.activeCat = null
      this.showCats = true
      this.$store.dispatch("clearJobsByCategory")
    },
    catCheck() {
      console.log('check')
      this.activeCat = this.skillCategories[0]  
    },
    selectCat(cat) {
      this.activeCat = cat
      this.showCats = false
      this.$store.dispatch("getJobsByCategory", this.activeCat)
    },
    changeCat() {
      this.$store.dispatch("clearJobsByCategory")
      this.$store.dispatch("getJobsByCategory", this.activeCat)
    },
    // updateProfile(){
    //   if (this.submitButton) {
    //     console.log("has button")
    //   } else {
    //     let userProfile = this.userProfile
    //     this.$store.dispatch('updateUser', userProfile)
    //   }
      
    // },
    deleteSkill(job) {
      this.$store.dispatch('deleteSkill', {
        skill: job,
        user: this.userProfile
      })
    },
    updateSkills(){
      this.performingRequest = true
      let userProfile = this.userProfile
      this.$store.dispatch('updateUser', userProfile)
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },
  },
  beforeDestroy () {
    this.$store.dispatch("clearJobsState")
    this.$store.dispatch("clearSkillCategories")
  }

}
</script>