<template>
  <div v-if="(userProfile  && userProfile.id)" class="mt-2">
    
      <ProfileSkills :userProfile="userProfile" :showtitle="false" :lout="true" :h2="false" />






  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileSkills from '@/components/Profile/ProfileSkills.vue'

export default {
  name: 'accountSkills',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
   components: {
    Loader,
    ProfileSkills,
  },
}
</script>